import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@myfood/myfood-domain';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./shared/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./shared/logout/logout.module').then((m) => m.LogoutPageModule),
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./shared/redirecionador/redirecionador.module').then(
        (m) => m.RedirecionadorPageModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./tabs-manager/tabs-manager.module').then(
        (m) => m.TabsPageModule,
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'produtos',
    loadChildren: () =>
      import('./produtos/produtos.module').then((m) => m.ProdutosPageModule),
  },
  {
    path: 'produto-detalhes',
    loadChildren: () =>
      import('./produto-detalhes/produto-detalhes.module').then(
        (m) => m.ProdutoDetalhesPageModule,
      ),
  },
  {
    path: 'pedidos',
    loadChildren: () =>
      import('./pedidos/pedidos.module').then((m) => m.PedidosPageModule),
  },
  {
    path: 'pedidos-status',
    loadChildren: () =>
      import('./pedidos-status/pedidos-status.module').then(
        (m) => m.PedidosStatusPageModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'redirect',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
