import { Component } from '@angular/core';
import { environment } from '../environments/environment.development';
import {
  LoginService,
  UsersService,
  ProfilesService,
  ProductsService,
  StoresService,
  OrdersService,
  SessionService,
} from '@myfood/myfood-domain';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly sessionService: SessionService,
    private readonly loginService: LoginService,
    private readonly usersService: UsersService,
    private readonly profilesService: ProfilesService,
    private readonly productsService: ProductsService,
    private readonly storesService: StoresService,
    private readonly ordersService: OrdersService,
  ) {
    this.initServices();
  }

  public initServices() {
    this.sessionService.init(
      environment.storageContext.accessTokenKeyName,
      environment.storageContext.refreshTokenKeyName,
      environment.storageContext.originsKeyName,
    );
    this.sessionService.setOrigins(environment.storageContext.origins);
    this.loginService.init(environment.usersApi);
    this.usersService.init(environment.usersApi);
    this.profilesService.init(environment.usersApi);
    this.storesService.init(environment.storesApi);
    this.productsService.init(environment.storesApi);
    this.ordersService.init(environment.ordersApi);
  }
}
