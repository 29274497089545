// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  usersApi: {
    endPointURL: 'https://myfood-dev.zapto.org:3001/v1',
    timeout: 3000,
    retries: 2,
  },
  storesApi: {
    endPointURL: 'https://myfood-dev.zapto.org:3002/v1',
    timeout: 3000,
    retries: 2,
  },
  ordersApi: {
    endPointURL: 'https://myfood-dev.zapto.org:3003/v1',
    timeout: 3000,
    retries: 2,
  },
  tracesApi: {
    endPointURL: 'https://myfood-dev.zapto.org:4317/v1/traces',
    serviceName: 'Myfood App',
    timeout: 3000,
    retries: 2,
  },
  viaCEP: 'https://viacep.com.br/ws/{postalCode}/json',
  storageContext: {
    accessTokenKeyName: 'store_access_token',
    refreshTokenKeyName: 'store_refresh_token',
    originsKeyName: 'store_origins',
    origins: ['http://localhost', 'https://myfood-dev.zapto.org'],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
